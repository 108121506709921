
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.news {
  $base-class: &;
  .tile-grid {
    grid-template-columns: 1fr 1fr 1fr;

    @include for-size(phone-portrait-down) {
      grid-template-columns: 1fr;
    }
  }

  &__list {
    margin-bottom: $gap;
  }

  &__pagination {
    justify-content: center;
  }

  &__sort {
    position: relative;
    height: 24px;
    line-height: 24px;
    z-index: 5;
    @include for-size(phone-landscape-down) {
      margin-right: 5px;
    }

    &:not(:last-child) {
      padding-right: 20px;
      border-right: 1px solid $color-black-op-25;
      margin-right: 20px;
    }

    &:hover {
      #{$base-class}__sort-list {
        visibility: visible;
        opacity: 1;
      }
      #{$base-class}__sort-btn {
        color: $primary-color-hover;
      }
    }
  }
  &__sort-btn {
    color: $color-black;
    transition: color $base-animation-time;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  &__sort-list {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    right: 0;
    min-width: 200px;
    background-color: $color-white;
    padding: 16px 20px;
    box-shadow: $base-shadow-l;
    border-radius: 8px;
    transition: visibility $base-animation-time, opacity $base-animation-time;
  }
  &__sort-item {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $color-black;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &:hover,
    &--active {
      cursor: pointer;
      color: $primary-color-hover;
    }
  }
}
